import axios from "axios";
import { hibrydEncrypt } from "helpers/cryptography";
import { activateCardToken } from "./activateCardToken";

export const createCardToken = async (
  params,
  cardConfigurations,
  authToken
) => {
  const { token_configuration: tokenConfiguration, brand } = cardConfigurations;
  const {
    name,
    cpfOrCnpj,
    number,
    cvv,
    expirationDate,
    deviceId,
    deviceSecretId,
    userUUID,
    email,
  } = params;

  const createCardTokenURL =
    "https://payment.ifood.com.br/v1/customers/me/card-tokens";

  const req = {
    "holder-name": name,
    "holder-document": cpfOrCnpj.replace(/\D/g, ""),
    "account-number": number?.replace(/\D/g, ""),
    "security-code": cvv.replace(/\D/g, ""),
    "expiry-month": parseInt(expirationDate.split("/")[0], 10),
    "expiry-year": parseInt(`20${expirationDate.split("/")[1]}`, 10),
    "zero-dollar-validation": {
      debit: false,
      credit: !tokenConfiguration?.providers[0]?.zero_dollar,
    },
    "additional-data": {
      alias: "crédito",
      "brand-id": brand.id,
      "device-payment-secret": deviceSecretId,
      "device-id": deviceId,
      tenant: "IFO",
      "card-type": "CREDIT",
      ip: "0.0.0.0",
      "external-account-validation-id":
        process.env.REACT_APP_EXTERNAL_ACCOUNT_VALIDATION_ID,
    },
  };

  const reqEncrypted = await hibrydEncrypt(
    req,
    (tokenConfiguration?.providers || [])[0]?.public_key
  );

  const paymentServiceData = {
    acquirers: (tokenConfiguration?.providers || [])[0]?.acquirers,
    buyer: {
      "first-name": name,
      "last-name": "",
      accountId: userUUID,
      email: email,
      document: cpfOrCnpj.replace(/\D/g, ""),
    },
    card: reqEncrypted,
  };

  const paymentServiceConfig = {
    headers: {
      account_id: userUUID,
      "X-Ifood-Device-Id": deviceId,
      country: "BR",
      platform: "Desktop",
      app_version: "",
    },
  };

  try {
    const cardRegister = await axios.post(
      createCardTokenURL,
      paymentServiceData,
      paymentServiceConfig
    );

    const tokenActive = await activateCardToken(authToken, cardRegister.data);

    if (!tokenActive.error) return cardRegister.data;

    return { error: tokenActive.error };
  } catch (error) {
    return { error };
  }
};
