import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Default, PaymentV2 } from "components/PaymentWidget";

const Page = () => {
  const location = useLocation();
  const { beta } = queryString.parse(location.search);

  return beta === "true" ? <PaymentV2 /> : <Default />;
};

export default Page;
