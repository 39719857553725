import React from "react";
import CheckIcon from "assets/icons/Check.svg";
import "./snackbar.css";

const Snackbar = ({ style, options, message }) => {
  const classes = ["snackbar"];

  switch (options.type) {
    case "success":
      classes.push("background--success");
      break;
    case "warning":
      classes.push("background--warning");
      break;
    case "info":
      classes.push("background--info");
      break;
    case "error":
      classes.push("background--danger");
      break;
    default:
      classes.push("background--info");
  }
  return (
    <div className={classes.join(" ")} style={style}>
      {options.type === "success" && (
        <div className='snackbar__icon'>
          <CheckIcon />
        </div>
      )}
      <p className='snackbar__message'>{message}</p>
    </div>
  );
};

export default Snackbar;
