import axios from "axios";

export const getPaymentMethods = async () => {
  const getMethodsURL =
    "https://marketplace.ifood.com.br/v1/payments/br/ifood-shop/payment-methods";

  try {
    const response = await axios.get(getMethodsURL, {
      headers: { platform: "DESKTOP" },
    });
    return response.data;
  } catch (error) {
    return { error };
  }
};
