import './card.css';

const Card = ({ className, children, props }) => {
	const classes = ['card'];
	if (className) classes.push(className);

	return (
		<div className={classes.join(' ')} {...props}>
			{children}
		</div>
	);
};

export default Card;
