import { BigInteger } from "jsbn";

/*
This code is an implementation of rsa cryptography, you can read more about it in this article
http://www-cs-students.stanford.edu/~tjw/jsbn/
http://www-cs-students.stanford.edu/~tjw/jsbn/jsbn.js

Important the original code expects a string to do encription, this code expects an array buffer.

This is a small diference between the original code and this implementation
*/

const BASE_64_MAP =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const BASE_64_PADDING = "=";

function hex2b64(hex) {
  let i;
  let chartCode;
  let result = "";
  for (i = 0; i + 3 <= hex.length; i += 3) {
    chartCode = parseInt(hex.substring(i, i + 3), 16);
    result +=
      BASE_64_MAP.charAt(chartCode >> 6) + BASE_64_MAP.charAt(chartCode & 63);
  }
  if (i + 1 === hex.length) {
    chartCode = parseInt(hex.substring(i, i + 1), 16);
    result += BASE_64_MAP.charAt(chartCode << 2);
  } else if (i + 2 === hex.length) {
    chartCode = parseInt(hex.substring(i, i + 2), 16);
    result +=
      BASE_64_MAP.charAt(chartCode >> 2) +
      BASE_64_MAP.charAt((chartCode & 3) << 4);
  }
  while ((result.length & 3) > 0) result += BASE_64_PADDING;

  return result;
}

function Arcfour() {
  this.i = 0;
  this.j = 0;
  this.S = [];
}

function ARC4init(key) {
  let i;
  let j;
  let t;
  for (i = 0; i < 256; ++i) this.S[i] = i;
  j = 0;
  for (i = 0; i < 256; ++i) {
    j = (j + this.S[i] + key[i % key.length]) & 255;
    t = this.S[i];
    this.S[i] = this.S[j];
    this.S[j] = t;
  }
  this.i = 0;
  this.j = 0;
}

function ARC4next() {
  this.i = (this.i + 1) & 255;
  this.j = (this.j + this.S[this.i]) & 255;
  const t = this.S[this.i];
  this.S[this.i] = this.S[this.j];
  this.S[this.j] = t;
  return this.S[(t + this.S[this.i]) & 255];
}

function prngNewstate() {
  return new Arcfour();
}

const rngPsize = 256;

let rngState;
let rngPool;
let rngPptr;

function rngSeedInt(x) {
  rngPool[rngPptr++] ^= x & 255;
  rngPool[rngPptr++] ^= (x >> 8) & 255;
  rngPool[rngPptr++] ^= (x >> 16) & 255;
  rngPool[rngPptr++] ^= (x >> 24) & 255;
  if (rngPptr >= rngPsize) rngPptr -= rngPsize;
}

function rngSeedTime() {
  rngSeedInt(new Date().getTime());
}

if (rngPool == null) {
  rngPool = [];
  rngPptr = 0;
  let t;
  if (window.crypto && window.crypto.getRandomValues) {
    const ua = new Uint8Array(32);
    window.crypto.getRandomValues(ua);
    for (t = 0; t < 32; ++t) rngPool[rngPptr++] = ua[t];
  }
  while (rngPptr < rngPsize) {
    t = window.crypto.getRandomValues(new Uint32Array(1))[0];
    rngPool[rngPptr++] = t >>> 8;
    rngPool[rngPptr++] = t & 255;
  }
  rngPptr = 0;
  rngSeedTime();
}

function rngGetByte() {
  if (rngState == null) {
    rngSeedTime();
    rngState = prngNewstate();
    rngState.init(rngPool);
    for (rngPptr = 0; rngPptr < rngPool.length; ++rngPptr) rngPool[rngPptr] = 0;
    rngPptr = 0;
  }

  return rngState.next();
}

function rngGetBytes(ba) {
  for (let i = 0; i < ba.length; ++i) ba[i] = rngGetByte();
}

function SecureRandom() {}

function pkcs1pad2(arrayBuffer, size) {
  if (size < arrayBuffer.length + 11) {
    throw new Error("Message too long for RSA");
  }
  const encryptedArrayBuffer = [];
  let i = arrayBuffer.length - 1;
  while (i >= 0 && size > 0) {
    // Important: this code is different from the orignal code
    const chart = arrayBuffer[i--];
    if (chart < 128) {
      encryptedArrayBuffer[--size] = chart;
    } else if (chart > 127 && chart < 2048) {
      encryptedArrayBuffer[--size] = (chart & 63) | 128;
      encryptedArrayBuffer[--size] = (chart >> 6) | 192;
    } else {
      encryptedArrayBuffer[--size] = (chart & 63) | 128;
      encryptedArrayBuffer[--size] = ((chart >> 6) & 63) | 128;
      encryptedArrayBuffer[--size] = (chart >> 12) | 224;
    }
  }
  encryptedArrayBuffer[--size] = 0;
  const rng = new SecureRandom();
  const paddingArray = [];
  while (size > 2) {
    paddingArray[0] = 0;
    while (paddingArray[0] === 0) rng.nextBytes(paddingArray);
    encryptedArrayBuffer[--size] = paddingArray[0];
  }
  encryptedArrayBuffer[--size] = 2;
  encryptedArrayBuffer[--size] = 0;
  return new BigInteger(encryptedArrayBuffer);
}

// "empty" RSA key constructor
function RSAKey() {
  this.n = null;
  this.e = 0;
  this.d = null;
  this.p = null;
  this.q = null;
  this.dmp1 = null;
  this.dmq1 = null;
  this.coeff = null;
}

// Set the public key fields N and e from hex strings
function RSASetPublic(N, E) {
  if (N != null && E != null && N.length > 0 && E.length > 0) {
    this.n = new BigInteger(N, 16);
    this.e = parseInt(E, 16);
  } else throw new Error("Invalid RSA public key");
}

function RSADoPublic(x) {
  return x.modPowInt(this.e, this.n);
}

function RSAEncrypt(text) {
  const m = pkcs1pad2(text, (this.n.bitLength() + 7) >> 3);
  if (m == null) return null;
  const c = this.doPublic(m);
  if (c == null) return null;
  const h = c.toString(16);
  if ((h.length & 1) === 0) {
    return h;
  }

  return `0${h}`;
}

function RSAEncryptB64(text) {
  const h = this.encrypt(text);
  if (h) {
    return hex2b64(h);
  }

  return null;
}

SecureRandom.prototype.nextBytes = rngGetBytes;

Arcfour.prototype.init = ARC4init;
Arcfour.prototype.next = ARC4next;

RSAKey.prototype.doPublic = RSADoPublic;
RSAKey.prototype.setPublic = RSASetPublic;
RSAKey.prototype.encrypt = RSAEncrypt;
RSAKey.prototype.encrypt_b64 = RSAEncryptB64;

export default RSAKey;
