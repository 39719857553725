export const maskCreditCard = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskMonthYear = [/\d/, /\d/, "/", /\d/, /\d/];

export const cnpjMask = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const documentMask = (document) => {
  const value = document.replace(/\D/g, "");

  return value.length < 12 ? cpfMask : cnpjMask;
};

export const maskMaker = (mask, nonCharactersList) => {
  return (raw) => {
    const length = raw.length;
    const nonDefault = nonCharactersList.some((characterIndex) => {
      return characterIndex - 1 === length;
    });
    return nonDefault ? mask.slice(0, length) : mask;
  };
};
