import React, { useCallback } from "react";
import MaskedInput from "react-text-mask";

import Label from "components/Label";
import Error from "components/Error";

import "./input.css";

function Input({ color, error, label, hasValue, innerRef, mask, ...props }) {
  const classes = `simple-form-input ${
    error ? "simple-form-input--invalid" : ""
  } ${color ? `simple-form-input--${color}` : ""}`;

  const labelClassDefault = "simple-form-input__label";

  const classesVariants = useCallback((name) => {
    const options = {
      cvv: `${labelClassDefault}--cvv`,
      number: `${labelClassDefault}--card-number`,
      withValue: `${labelClassDefault}--has-value`,
      default: "",
    };

    return options[name] ?? options.default;
  }, []);

  const labelClasses = () => {
    const withValue = classesVariants(hasValue && "withValue");

    const adictionalClass = classesVariants(props.name);

    return `${labelClassDefault} ${withValue} ${adictionalClass}`;
  };

  return (
    <div className={classes}>
      <Label variant={labelClasses()} name={label}>
        <MaskedInput
          mask={false}
          {...props}
          {...mask}
          className='simple-form-input__field'
          render={(ref, childrenProps) => (
            <input
              ref={(el) => {
                ref(el);
                innerRef(el);
              }}
              {...childrenProps}
            />
          )}
        />
      </Label>
      <Error variant='simple-form-input__error' error={error} />
    </div>
  );
}

export default React.memo(Input);
