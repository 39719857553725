import { useState } from "react";
import { getTokenCard } from "services/creditCard";
import Header from "components/Header";
import CardForm from "components/CardForm";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Default = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { callback } = queryString.parse(location.search);
  const alert = useAlert();

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const token = await getTokenCard(data);
      if (!token.error) {
        window.location.href = callback;
      } else {
        alert.error("Houve um erro ao adicionar cartão. Tente novamente");
      }
    } catch (error) {
      alert.error("Houve um erro ao adicionar cartão. Tente novamente");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <CardForm onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export default Default;
