import axios from "axios";
import { Cookies } from "react-cookie";

export const getTokenCard = async (data) => {
  const cookies = new Cookies();

  const splittedExpirationDate = data?.expirationDate?.split("/") || [];

  const req = {
    card_number: data?.number?.replace(/ /g, ""),
    holder_name: data?.name,
    expiration_month: splittedExpirationDate[0],
    expiration_year: "20" + splittedExpirationDate[1],
    security_code: data?.cvv,
  };

  try {
    const zoopReq = await axios.post(
      "https://payment.ifood.com.br/v1/ifood-shop/card-tokens",
      req,
      {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      }
    );

    const now = new Date();

    const cardData = JSON.stringify({
      ok: true,
      token: zoopReq.data?.id,
      bin: req.card_number?.substring(0, 6),
      lastNumbers: req.card_number?.substring(req.card_number?.length - 4),
      expirationMonth: parseInt(zoopReq.data?.card?.expiration_month),
      expirationYear: parseInt(
        zoopReq.data?.card?.expiration_year?.substring(2, 4)
      ),
      name: zoopReq.data?.card?.holder_name,
      brand: zoopReq.data?.card?.card_brand,
    });

    cookies.set("zoopResponse", cardData, {
      domain: ".ifood.com.br",
      expires: new Date(now.getTime() + 300 * 1000),
      secure: true,
    });

    return zoopReq.data;
  } catch (error) {
    return { error };
  }
};
