import * as yup from "yup";
import {
  validateCpf,
  validateCardExpirationDate,
  validateCardNumberFromOptions,
} from "helpers/validators";

const FULL_NAME_REGEX =
  /^[A-Za-zÀ-ÖØ-öø-ÿ]([-']?[A-Za-zÀ-ÖØ-öø-ÿ]+)*( [A-Za-zÀ-ÖØ-öø-ÿ]([-']?[A-Za-zÀ-ÖØ-öø-ÿ]+)*)+$/;

const schemaObject = {
  number: yup
    .string()
    .test(
      "isCardNumberValid",
      "Insira um número válido de 0-16 dígitos",
      validateCardNumberFromOptions
    ),
  expirationDate: yup
    .string()
    .test(
      "isCardExpirationValid",
      "Insira uma data válida",
      validateCardExpirationDate
    ),
  cvv: yup
    .string()
    .min(3, "Mínimo de 3 caracteres")
    .required("Insira um CVV válido"),
  name: yup
    .string()
    .matches(FULL_NAME_REGEX, {
      message: "Informe-nos seu nome completo",
      excludeEmptyString: true,
    })
    .required("Nome completo é obrigatório"),
};

const schemaDocument = {
  cpfOrCnpj: yup
    .string()
    .test("isDocumentValid", "Insira um documento válido", validateCpf)
    .required("Documento é obrigatório"),
};

function handleSchema(isBeta) {
  if (isBeta) return yup.object().shape({ ...schemaObject, ...schemaDocument });
  return yup.object().shape(schemaObject);
}

export { handleSchema };
