import React from 'react'
import classNames from 'classnames'

const Grid = ({
	children = '',
	align = 'stretch',
	justify = '',
	wrap = false,
	direction = '',
	className = '',
	...props
}) => {
  const cx = classNames({
    'flex-grid': true,
    [`flex-grid--${direction}`]: direction !== '',
    [`flex-grid--align-${align}`]: align !== '',
    [`flex-grid--justify-${justify}`]: justify !== '',
    'flex-grid--wrap': wrap,
    [`${className}`]: className !== '',
  });

  return (
    <div {...props} className={cx}>
      {children}
    </div>
  );
}

export default Grid
