import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as PrevIcon } from 'assets/icons/Prev.svg';
import { Grid } from 'components/Grid';
import { Link } from 'components/Link';
import './header.css';

function Header() {
  return (
    <header className="checkout-header">
      <div className="container">
        <Grid align="center" wrap>
          <Grid.Item md={1} xs={12} />
          <Grid.Item md={1} xs={12} className="checkout-header__logo">
            <Link href="/" className="checkout-header__prev-icon">
              <PrevIcon />
            </Link>
            <Link href="https://shop.ifood.com.br">
              <Logo
                className="checkout-header__logo-img"
              />
            </Link>
            <div />
          </Grid.Item>
          <Grid.Item md={8} xs={12} />
          <Grid.Item className="checkout-header__prev" md={1} xs={12}>
            <Link href="#" onClick={() =>window.history.back()}>Voltar</Link>
          </Grid.Item>
          <Grid.Item md={1} xs={12} />
        </Grid>
      </div>
    </header>
  );
}

export default Header;
