import Payment from "payment";

const REMOVE_SPACES = /\s/g;

export const validateCardExpirationDate = (date) => {
  const dateFormatRegex = /\d{2}\/\d{2}/;
  const isDateInRightFormat = dateFormatRegex.test(date);

  if (!date || !isDateInRightFormat) {
    return false;
  }

  const [month, year] = date.split("/");
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear().toString().substr(-2);

  if (month < 1 || month > 12) {
    return false;
  }

  return !(year < currentYear || (month < currentMonth && year <= currentYear));
};

export const validateCardNumberFromOptions = (cardNumber) => {
  return Payment.fns.validateCardNumber(cardNumber.replace(REMOVE_SPACES, ""));
};

export function isCPFValid(strCPF) {
  if (strCPF === undefined || strCPF === "") return false;

  const cpf = strCPF.replace(/[^\d]+/g, "");
  const regexRepeatedNumber = /(\d)\1{10}/;

  if (cpf.length !== 11 || regexRepeatedNumber.test(cpf)) return false;

  let add = 0;

  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i), 10) * (10 - i);

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  if (rev !== parseInt(cpf.charAt(9), 10)) return false;

  add = 0;

  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i), 10) * (11 - i);

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  return rev === parseInt(cpf.charAt(10), 10);
}

export function validateCpf(value) {
  if (value && value.length > 0 && value.length <= 14) {
    return isCPFValid(value);
  }
  return true;
}

export function validateLegalPerson(document) {
  if (document.length <= 0) return false;

  document = document.replace(/[^\d]+/g, "");

  const regexRepeatedNumber = /(\d)\1{13}/;

  if (regexRepeatedNumber.test(document)) return false;

  // Valida DVs
  let length = document.length - 2;
  let numbers = document.substring(0, length);
  let digits = document.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== digits.charAt(0)) {
    return false;
  }

  length = length + 1;
  numbers = document.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return result === digits.charAt(1);
}
