import axios from "axios";

export const activateCardToken = async (authToken, data) => {
  const activeCardTokenURL =
    "https://payment.ifood.com.br/v1/customers/me/card-tokens-ifood-shop/-/activation";

  const paymentServiceConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  try {
    const cardActive = await axios.post(
      activeCardTokenURL,
      data,
      paymentServiceConfig
    );

    return cardActive.data;
  } catch (error) {
    return { error };
  }
};
