import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  documentMask,
  maskCreditCard,
  maskMonthYear,
  maskMaker,
} from "helpers/masks";
import Input from "components/Input";
import Button from "components/Button";
import Card from "components/Card";
import CreditCard from "components/CreditCard";
import { Grid } from "components/Grid";
import "./paymentForm.css";
import { handleSchema } from "./helpers/schema";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const MONTH_YEAR_2DIGITS = /(\d{2}\/)(?:\d{2})(\d{1,2})/;

export const formatExpirationDatePipe = (v = "", config) => {
  let formattedValue = v;
  const { rawValue } = config;
  if (/\d{2}\/\d{4,}/.test(rawValue)) {
    const matchedValues = rawValue.match(MONTH_YEAR_2DIGITS);
    formattedValue = matchedValues[1] + matchedValues[2];
    return formattedValue;
  }
  if (v.length === 5 && rawValue.length > 5) return v;

  return formattedValue;
};

function CardForm({ onSubmit, isLoading }) {
  const [focusEl, setFocusEl] = useState();

  const location = useLocation();
  const { beta } = queryString.parse(location.search);

  const values = {
    number: "",
    name: "",
    expirationDate: "",
    cvv: "",
  };

  const { register, errors, watch, handleSubmit } = useForm({
    resolver: yupResolver(handleSchema(beta === "true")),
    defaultValues: beta !== "true" ? values : { ...values, cpfOrCnpj: "" },
  });

  const handleInputFocus = (e) => {
    setFocusEl(e.target.name);
  };

  const watchAllFields = watch();

  return (
    <div className='background--grey'>
      <Grid justify='center' wrap>
        <Grid.Item xs={12} md={6}>
          <h2
            style={{
              marginTop: "48px",
              marginBottom: "24px",
              color: "#3e3e3e",
              fontWeight: 500,
            }}
            size='24px'
            block
            weight='medium'
          >
            Insira os dados cartão
          </h2>

          <Card className='payment-method-form'>
            <div className='payment-method-form__preview'>
              <CreditCard
                cvv={watchAllFields.cvv}
                expiry={watchAllFields.expirationDate}
                focused={focusEl}
                name={watchAllFields.name}
                number={watchAllFields.number}
              />
            </div>
            <div className='container payment-method-form__container'>
              <form
                className='payment-method-form__form-container'
                onSubmit={handleSubmit((card) => onSubmit(card))}
              >
                <Input
                  className='card-form__input'
                  tabIndex='0'
                  name='number'
                  hasValue={!!watchAllFields.number}
                  error={errors.number?.message}
                  id='payment-method-input-number'
                  autoComplete='cc-number'
                  innerRef={register}
                  label='Número do cartão'
                  mask={{
                    mask: maskMaker(maskCreditCard, [5, 10, 15]),
                    guide: false,
                  }}
                  onFocus={handleInputFocus}
                />

                <div className='card-form__smaller-fields'>
                  <Input
                    className='card-form__input card-form__input--expirationDate'
                    name='expirationDate'
                    hasValue={!!watchAllFields.expirationDate}
                    error={errors.expirationDate?.message}
                    id='payment-method-input-expirationDate'
                    type='tel'
                    autoComplete='cc-exp'
                    innerRef={register}
                    label='Validade'
                    mask={{
                      mask: maskMaker(maskMonthYear, [3]),
                      guide: false,
                      pipe: formatExpirationDatePipe,
                    }}
                    onFocus={handleInputFocus}
                  />

                  <Input
                    className='card-form__input card-form__input--cvv'
                    type='tel'
                    maxLength={4}
                    name='cvv'
                    hasValue={!!watchAllFields.cvv}
                    error={errors.cvv?.message}
                    id='payment-method-input-cvv'
                    autoComplete='cc-cvv'
                    innerRef={register}
                    label='CVV'
                    onFocus={handleInputFocus}
                  />
                </div>

                <Input
                  className='card-form__input'
                  tabIndex='0'
                  name='name'
                  hasValue={!!watchAllFields.name}
                  error={errors.name?.message}
                  id='payment-method-input-name'
                  type='text'
                  autoComplete='cc-name'
                  innerRef={register}
                  label='Nome impresso no cartão'
                  onFocus={handleInputFocus}
                />
                {beta === "true" && (
                  <Input
                    className='card-form__input'
                    tabIndex='0'
                    name='cpfOrCnpj'
                    hasValue={!!watchAllFields.cpfOrCnpj}
                    error={errors.cpfOrCnpj?.message}
                    id='payment-method-input-cpfOrCnpj'
                    type='text'
                    mask={{
                      mask: documentMask,
                      guide: false,
                    }}
                    autoComplete='cc-name'
                    innerRef={register}
                    label='CPF/CNPJ do titular'
                    onFocus={handleInputFocus}
                  />
                )}

                <Button
                  className={`card-form__button ${
                    isLoading ? "card-form__button--loading" : ""
                  }`}
                  type='submit'
                  text={isLoading ? "..." : "Adicionar"}
                  disabled={isLoading}
                />
              </form>
            </div>
          </Card>
        </Grid.Item>
      </Grid>
    </div>
  );
}

export default CardForm;
