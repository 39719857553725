import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider as SnackbarProvider } from "react-alert";
import Snackbar from "components/Snackbar";
import Home from "pages";

function App() {
  /**
   * Define constants
   */
  const alertOptions = {
    timeout: 5000,
    position: "bottom left",
  };

  return (
    <BrowserRouter>
      <Switch>
        <SnackbarProvider template={Snackbar} {...alertOptions}>
          <Route exact path='/'>
            <Home />
          </Route>
        </SnackbarProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
