import React from 'react'
import classNames from 'classnames'

const Item = ({
	children = '',
	flex = false,
	grow = false,
	shrink = false,
	align = '',
	xs = 0,
	sm = 0,
	md = 0,
	lg = 0,
	className = '',
	...props
}) => {
  const cx = classNames({
    'flex-grid__item': true,
    'flex-grid__item--grow': grow,
    'flex-grid__item--shrink': shrink,
    'flex-grid__item--flex': flex,
    [`flex-grid__item--align-${align}`]: align !== '',
    [`flex-grid__item--xs-${xs}`]: xs,
    [`flex-grid__item--sm-${sm}`]: sm,
    [`flex-grid__item--md-${md}`]: md,
    [`flex-grid__item--lg-${lg}`]: lg,
    [`${className}`]: className !== '',
  });

  return (
    <div {...props} className={cx}>
      {children}
    </div>
  );
}

export default Item
