import React from 'react'
import './link.css'

const Link = ({ className, children, ...props }) => {
  const classes = ['link']
  if (className) classes.push(className)

  const linkProps = {
    className: classes.join(' '),
    ...props
  }

  return <a { ...linkProps }> { children } </a>
}

export { Link }
