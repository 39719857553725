import axios from "axios";

export const getFormattedErrorMessage = async (
  data,
  authToken,
  deviceSecretId
) => {
  const config = {
    method: "put",
    url:
      "https://payment.ifood.com.br/v1/customers/me/card-tokens-ifood-shop/-/invalidation",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
      "X-Ifood-Device-Payment-Secret": deviceSecretId,
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return JSON.stringify(response.data);
  } catch (error) {
    console.error(`[Get formatted error]: ${error?.message}`);
  }
};
