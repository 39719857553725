import { useState, useEffect } from "react";
import Header from "components/Header";
import CardForm from "components/CardForm";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCookies } from "react-cookie";

import Payment from "payment";
import {
  createCardToken,
  getPaymentMethods,
  getFormattedErrorMessage,
} from "services/cardManager";

const PaymentV2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { callback } = queryString.parse(location.search);
  const alert = useAlert();
  const [cookies] = useCookies();
  const [paymentMethod, setPaymentMethod] = useState();

  const fetchPaymentMethods = async () => {
    const payments = await getPaymentMethods();

    if (payments && !payments.error)
      return setPaymentMethod(
        payments.filter((p) => p.method.name === "CREDIT")
      );
    console.error(`[Payment Methods:] ${payments?.error?.message}`);
  };

  useEffect(() => fetchPaymentMethods(), []);

  const getCardBrandName = (number) => Payment.fns.cardType(number);

  const getTokenFromCardBrand = (brand) =>
    paymentMethod?.find((p) => p.brand.name.toUpperCase() === brand);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const userInfo = JSON.parse(atob(cookies.app));

      const authToken = cookies.shopToken;
      const params = {
        ...data,
        deviceId: userInfo.deviceId,
        deviceSecretId: userInfo.devicePaymentSecret,
        email: userInfo.email,
        userUUID: userInfo.userUUID,
      };

      const brandName = getCardBrandName(data.number);

      const cardConfigurations = getTokenFromCardBrand(brandName.toUpperCase());

      const token = await createCardToken(
        params,
        cardConfigurations,
        authToken
      );

      if (!token.error) {
        window.location.href = callback;
      } else {
        alert.error("Houve um erro ao adicionar cartão. Tente novamente");

        const formattedError = await getFormattedErrorMessage(
          token.error.response.data,
          authToken,
          userInfo.devicePaymentSecret
        );

        console.error("[Credit card error]: ", formattedError?.message);
      }
    } catch (error) {
      console.error("[Credit card error]: ", error);
      alert.error("Houve um erro ao adicionar cartão. Tente novamente");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <CardForm onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export default PaymentV2;
